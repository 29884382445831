import { computed, ref, watch } from '@vue/composition-api'
import store from '@/store'
import http from '@/global/http'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUserData } from '@/auth/utils'

export default function useContractsList() {
  const toast = useToast()

  const contracts = ref([])

  const userList = ref([])
  const userListPKH = ref([])
  const departmentList = ref([])
  const statusList = ref([])
  const filterChange = ref(0)
  const statusFilter = ref('')
  const categoryFilter = ref('')
  const hasPlanFilter = ref('')
  const isAssignedFilter = ref('')
  const userFilter = ref('')
  const departmentFilter = ref('')
  const hasCollectedFilter = ref('')
  const rangeDateFilter = ref('')
  const searchQuery = ref('')
  const typeDateFilter = ref('')
  const warningTextFilter = ref('')

  const statisticData = ref({})

  const userData = getUserData()

  const refContractsListTable = ref(null)

  watch([userList], () => {
    userListPKH.value = userList.value.length ? userList.value.filter(item => (item.department && item.department.code) === 'PKH') : []
  })
  // Table Handlers
  const tableColumns = [
    {
      label: 'Hồ sơ',
      key: 'ho-so',
    },
    {
      label: 'NV PKH',
      key: 'nv-pkh',
    },
    {
      label: 'Đội',
      key: 'phong-ban',
    },
    {
      label: 'Khách hàng',
      key: 'khach-hang',
      thClass: 'min-width-250',
    },
    {
      label: 'Khách nợ',
      key: 'khach-no',
      thClass: 'min-width-250',
    },
    {
      label: 'Giá trị',
      key: 'gia-tri',
    },
  ]
  const perPage = ref(10)
  const currentPage = ref(1)
  const totalContracts = ref(0)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref('createdTime')
  const isSortDirDesc = ref(true)

  const resultTitle = ref('')

  const clearFilter = initFilter => {
    const statusF = initFilter?.statusF
    statusFilter.value = statusF || ''
    categoryFilter.value = ''
    userFilter.value = ''
    departmentFilter.value = ''
    hasCollectedFilter.value = ''
    searchQuery.value = ''
    rangeDateFilter.value = ''
    hasPlanFilter.value = ''
    isAssignedFilter.value = ''
    typeDateFilter.value = ''
  }

  const departmentOptions = computed({
    get: () => {
      let list = departmentList.value.filter(d => d.code.toLowerCase()
        .startsWith('d'))
      list = list.sort((a, b) => (a.code < b.code ? -1 : 1))
      return list
    },
    set: () => {
    },
  })

  const updateStatistic = () => {
    const obj = {}
    contracts.value.forEach(c => {
      const val = obj[c.status]
      obj[c.status] = val ? val + 1 : 1
    })
    const arr = []
    Object.keys(obj || [])
      .forEach(key => {
        arr.push({
          key,
          value: obj[key],
        })
      })
    statisticData.value = arr
  }

  const mapDataContractsList = list => list
    .map(item => Object.assign(item, {
      'ho-so': item.document_code,
      'nv-pkh': item.creator,
      'phong-ban': item.department_name,
      'khach-hang': item.customerName,
      'khach-no': item.debtorName,
      'gia-tri': item.feeForConsultant,
    }))

  const fcHasPlan = v => {
    switch (v) {
      case 'false':
      case false:
        return 0
      case 'true':
      case true:
        return 1
      default:
        return v
    }
  }

  const getBuildFilterForContractList = filter => {
    const sFilter = statusFilter.value ? [statusFilter.value] : []

    const typeDate = typeDateFilter.value

    let dateByStatusContractSigned = sFilter[0] && sFilter[0].includes('contract_signed')
    let dateByStatusLiquidityProcessing = sFilter.join(',') === 'liquidity_processing'
    let dateByStatusLiquidityCompleted = sFilter.join(',') === 'liquidity_completed'
    let dateByCollectAt = ''
    let dateByDefault = !(dateByStatusContractSigned || dateByStatusLiquidityProcessing || dateByStatusLiquidityCompleted)

    if (typeDate === 'default') {
      dateByStatusContractSigned = ''
      dateByStatusLiquidityProcessing = ''
      dateByStatusLiquidityCompleted = ''
      dateByCollectAt = ''
      dateByDefault = true
    }

    if (typeDate === 'from_hand_over_at') {
      dateByStatusContractSigned = ''
      dateByStatusLiquidityProcessing = true
      dateByStatusLiquidityCompleted = ''
      dateByCollectAt = ''
      dateByDefault = ''
    }

    if (typeDate === 'from_collect_at') {
      dateByStatusContractSigned = ''
      dateByStatusLiquidityProcessing = ''
      dateByStatusLiquidityCompleted = ''
      dateByCollectAt = true
      dateByDefault = ''
    }

    if (typeDate === 'contract_signed') {
      dateByStatusContractSigned = true
      dateByStatusLiquidityProcessing = ''
      dateByStatusLiquidityCompleted = ''
      dateByCollectAt = ''
      dateByDefault = ''
    }

    if (dateByStatusContractSigned) warningTextFilter.value = 'Thời gian tìm kiếm là: Ngày ký thực tế'
    if (dateByStatusLiquidityProcessing) warningTextFilter.value = 'Thời gian tìm kiếm là: Ngày chờ thanh lý'
    if (dateByStatusLiquidityCompleted) warningTextFilter.value = 'Thời gian tìm kiếm là: Ngày thanh lý'
    if (dateByCollectAt) warningTextFilter.value = 'Thời gian tìm kiếm là: Ngày HĐ đòi được'
    if (dateByDefault) warningTextFilter.value = ''

    const [from, to] = rangeDateFilter.value ? rangeDateFilter.value.split(' to ') : ['', '']
    return http.buildQuery(filter || {
      page: currentPage.value - 1,
      perpage: perPage.value,
      category: categoryFilter.value,
      from_sign_at: dateByStatusContractSigned ? http.getISODate(from) : undefined,
      to_sign_at: dateByStatusContractSigned ? http.getISODate(to ? `${to}T23:59:59` : '') : undefined,
      from_hand_over_at: dateByStatusLiquidityProcessing ? http.getISODate(from) : undefined,
      to_hand_over_at: dateByStatusLiquidityProcessing ? http.getISODate(to ? `${to}T23:59:59` : '') : undefined,
      from_liquidity_finish_at: dateByStatusLiquidityCompleted ? http.getISODate(from) : undefined,
      to_liquidity_finish_at: dateByStatusLiquidityCompleted ? http.getISODate(to ? `${to}T23:59:59` : '') : undefined,
      from_collect_at: dateByCollectAt ? http.getISODate(from) : undefined,
      to_collect_at: dateByCollectAt ? http.getISODate(to ? `${to}T23:59:59` : '') : undefined,
      from: dateByDefault ? http.getISODate(from) : undefined,
      to: dateByDefault ? http.getISODate(to ? `${to}T23:59:59` : '') : undefined,
      creator_id: userFilter.value || '',
      department_id: departmentFilter.value,
      has_collected: fcHasPlan(hasCollectedFilter.value),
      m_status: sFilter.join(','),
      text_string: searchQuery.value,
      is_has_plan: fcHasPlan(hasPlanFilter.value),
      is_assigned: fcHasPlan(isAssignedFilter.value),
    }) || ''
  }

  const fetchContracts = filter => {
    const queryString = getBuildFilterForContractList(filter)

    return http.api(store.dispatch('contract/fetchContracts', queryString), res => {
      contracts.value = res?.data || []
      // const list = mapDataContractsList(res?.data || [])
      totalContracts.value = res.total
      resultTitle.value = `Tìm thấy <span class="text-primary">${totalContracts.value}</span> kết quả`
    })
      .catch(e => {
        toast({
          component: ToastificationContent,
          props: {
            title: e,
            variant: 'danger',
          },
        })
        throw e
      })
  }

  const getLinkExportContracts = () => {
    const [from, to] = rangeDateFilter.value ? rangeDateFilter.value.split(' to ') : ['', '']

    const queryString = http.buildQuery({
      from: http.getISODate(from),
      to: http.getISODate(to),
      creator_id: userFilter.value || '',
      department_id: departmentFilter.value,
    })

    return http.api(store.dispatch('contract/exportContracts', queryString))
      .catch(e => {
        toast({
          component: ToastificationContent,
          props: {
            title: e,
            variant: 'danger',
          },
        })
        throw e
      })
  }

  const filterContracts = fetchContracts

  watch(contracts, () => {
    updateStatistic()
  })

  watch([statusFilter, categoryFilter, userFilter, departmentFilter, searchQuery, rangeDateFilter, currentPage, typeDateFilter, perPage, hasPlanFilter, isAssignedFilter, hasCollectedFilter], () => {
    filterChange.value += 1
    filterContracts()
  })

  return {
    filterContracts,
    getLinkExportContracts,
    fetchContracts,
    clearFilter,
    updateStatistic,
    resultTitle,
    contracts,
    statisticData,
    mapDataContractsList,
    tableColumns,
    perPage,
    currentPage,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refContractsListTable,
    userData,

    // Filters
    totalContracts,
    statusList,
    filterChange,
    statusFilter,
    categoryFilter,
    userListPKH,
    userList,
    hasPlanFilter,
    isAssignedFilter,
    userFilter,
    departmentList,
    departmentOptions,
    departmentFilter,
    hasCollectedFilter,
    searchQuery,
    rangeDateFilter,
    typeDateFilter,
    warningTextFilter,
  }
}
