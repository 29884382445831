import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDepartments: (ctx, q) => axiosIns.get(`/api.department${q || ''}`),
    fetchDepartment: (ctx, { id }) => axiosIns.get(`/api.department/${id}`),
    createDepartment: (ctx, data) => axiosIns.post('/api.department', data),
    updateDepartment: (ctx, data) => axiosIns.put(`/api.department/${data.id}`, data),
    deleteDepartment: (ctx, { id }) => axiosIns.delete(`/api.department/${id}`),

    fetchDepartmentNotes: (ctx, q) => axiosIns.get(`/api.department-note${q || ''}`),
    createDepartmentNote: (ctx, data) => axiosIns.post('/api.department-note', data),
    updateDepartmentNote: (ctx, data) => axiosIns.put(`/api.department-note/${data.id}`, data),
    deleteDepartmentNote: (ctx, { id }) => axiosIns.delete(`/api.department-note/${id}`),

    fetchDepartmentReview: (ctx, q) => axiosIns.get(`/api.department-review${q || ''}`),
    createDepartmentReview: (ctx, data) => axiosIns.post('/api.department-review', data),
    updateDepartmentReview: (ctx, data) => axiosIns.put(`/api.department-review/${data.id}`, data),
    deleteDepartmentReview: (ctx, { id }) => axiosIns.delete(`/api.department-review/${id}`),
    fetchDebtCollectionReport: (ctx, q) => axiosIns.get(`/api.debt-collection/report/all${q}`),
    fetchUserActivities: (ctx, q) => axiosIns.get(`/api.activity${q || ''}`),
  },
}
